.my-container {
  padding: 10px 0;
}

.info-container {
  padding: 30px 0;
}

.my-card-deck {
  margin: 0 -10px;
}

.card {
  margin: 0 10px;
} 
 .custom-round{
  border-radius: 20%!important;
}
.custom-card{
  border-radius: 20%!important;
  /* background-color:mistyrose; */
  transition: transform .2s; /* Animation */
}
.custom-card:hover{
  
  transform: scale(0.9);
}
.laser-para {
  font-family: "Jost";
  font-style: normal;
  font-weight: 400;
  font-size: 16 px;
  line-height: 26px;
  letter-spacing: 0.06em;
  color: #4a4a49;
  margin-top: 20px;
}

.override-button {
  background-color: #fff !important;
  color: #CC00A2 !important;
  border-color: #CC00A2 !important;
  font-family: vanitasblackreg !important;
  font-weight: bold !important;
  font-size: 20px !important;

  letter-spacing: 0.09em !important;
}

.override-button:hover {
  background-color: #8064A2 !important;
  color: pink !important;
  border-color: pink !important;
}
.custom-footer{
  /* padding-top: 15px;
  padding-bottom: 5px; */
  margin-top: 15px;
}
@media (max-width: 767px) {
  .my-card-deck {
    flex-direction: column;
  }
}

/* 
 @media (max-width: 768px) {
    .carousel-caption {
      bottom: 0;
    }
  }


  .carousel-item {
    height: 100vh;
  }
  
  .carousel-item > img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .carousel-caption {
    position: absolute;
    bottom: 0;
    right: 15%;
    left: 15%;
    padding-top: 20px;
    padding-bottom: 20px;
    color: #fff;
    text-align: center;
    text-shadow: 0 1px 2px rgba(0, 0, 0, 0.6);
  }
  
  @media (max-width: 768px) {
    .carousel-caption {
      bottom: 15%;
    }
  }

 
  .carousel {
    max-height: 400px; 
  }
  
  .carousel-item img {
    max-height: 400px; 
  }
  
  @media (max-width: 768px) {
    .carousel {
      max-height: 200px; 
    }
    
    .carousel-item img {
      max-height: 200px;
    }
  } */