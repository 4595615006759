@import url("https://fonts.googleapis.com/css2?family=Asap&family=Roboto:ital,wght@0,500;0,900;1,500&display=swap");


header {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
  }
  .custom-card-about{
    border-radius: 20%!important;
    /* background-color:mistyrose; */
    transition: transform .2s; /* Animation */
    padding: 6px;
  }
  .custom-card-map{
    /* border-radius: 10%!important; */
    /* background-color:mistyrose; */
    transition: transform .2s; /* Animation */
    padding: 6px;
    
  }
  .custom-card-about:hover{
  
    transform: scale(1.5);
  }
header h1 {
    /* position: absolute; */
    width: 90%;
    font-size: 60px;
    /* font-weight: 600; */
    color: transparent;
    background-image: linear-gradient(to right ,#553c9a, #ee4b2b, #00c2cb, #ff7f50, #553c9a);
    -webkit-background-clip: text;
    background-clip: text;    
    background-size: 200%;
    background-position: -200%;
    animation: animated-gradient 2s infinite alternate-reverse;      
  }
  @keyframes animated-gradient{
    to{
      background-position: 200%;
    }
  }