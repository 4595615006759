.gallery {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  
  .gallery-image {
    width: 100%;
    height: auto;
    margin-bottom: 20px;
    cursor: pointer;
  }
  
  .modal-content {
    max-width: 100%;
    /* max-height: calc(100vh - 200px); */
  }
  
  .modal-content-image {
    max-width: 100%;
    max-height: 100%;
    margin-bottom: 20px;
    border: 2mm black solid;
    width: 100%;
  }
  /* .img-fluid{
    width: 100%;
    max-width: 100%;
    max-height: 100%;
  } */
  