
body{
    /* background-color: bisque;  */
   }
.my-container {
    padding: 10px 0;
  }
  .info-container{
    padding: 30px 0;
  }
  .my-card-deck {
    margin: 0 -10px;
    padding-top: 15px;
    


  }
  
  .card {
    margin: 0 10px;
    background-color:rgb(224, 37, 17);

  }
  .custom-round{
    border-radius: 20%!important;
  }
  .custom-card{
    border-radius: 20%!important;
    /* background-color:mistyrose; */
    transition: transform .2s; /* Animation */
  }

  .custom-card:hover{
  
    transform: scale(0.9);
  }

  .custom-service-footer{
    padding-top: 5px;
    padding-bottom: 5px;
  }
  .custom-footer{
    /* padding-top: 5px;
    padding-bottom: 5px; */
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .laser-para {
    font-family: "Jost";
    font-style: normal;
    font-weight: 400;
    font-size: 19px;
    line-height: 26px;
    letter-spacing: 0.06em;
    color: #4a4a49;
    /* color: black; */

    margin-top: 20px;
}
.item-image {
 order: 2;
}

.item-text {
order: 1;
}

  @media (max-width: 767px) {
    .my-card-deck {
      flex-direction: column;
    }
  }
  @media screen and (max-width: 768px) {
    .item-image {
      order: 1;

    }
  
    .item-text {
      order: 2;
    }
  }