

  .social-icon {
    /* display: inline-block; */
    margin-right: 30px;
  }
  .custom-card-map{
    /* border-radius:20%!important; */
    /* background-color:mistyrose; */
    transition: transform .2s; /* Animation */
    padding: 6px;
    /* border: 5px blanchedalmond solid; */
    
  }
 
  @media (max-width: 1000px) {
    .map-half {
      width: 200px!important;
    }
  }